import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, Observable, Subject, tap} from "rxjs";
import {
  ConversationMessagesResponse, ConversationResponse,
  ConversationsResponse,
  ConversationSummaryResponse, MessageResponse,
  NotificationResponse,
  NotificationsResponse, UserBasicResponse,
  UserFavsResponse
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {EventService} from "./event.service";
import {MessageRequest, StartConversationRequest} from "../../model/requests";

@Injectable()
export class ConversationService {

  private conversationsRetrieved = false
  private conversations: Conversation[] = []
  private unreadMessageCountSubject = new BehaviorSubject<number>(0)
  messageSubject: Subject<MessageResponse>

  constructor(
    private http: HttpClient,
    private eventService: EventService
  ) {
    eventService.subscribe('MESSAGE')
    this.messageSubject = eventService.messageSubject

    this.messageSubject.subscribe(message => {
      console.log('message', message)
      let conversation = this.conversations.find(conv => conv.id === message.conversationId)
      if (!conversation) {
        return
      }

      conversation.messages.unshift(message)
      if (message.senderUserId == conversation.otherUser.id) {
        conversation.unreadMessageCount++
        this.unreadMessageCountSubject.next(this.conversations.reduce((acc, conv) => acc + conv.unreadMessageCount, 0))
      }
    })
  }

  getUnreadMessageCount(): Observable<number> {
    if (!this.conversationsRetrieved) {
      setTimeout(() => {
          if (!this.conversationsRetrieved) {
            this.getConversations().subscribe()
          }
        },
        1000
      )
    }
    return this.unreadMessageCountSubject.asObservable()
  }

  getConversations(): Observable<ConversationsResponse> {
    this.conversationsRetrieved = true
    return this.http.get<ConversationsResponse>(`${environment.apiUrl}/conversations`).pipe(
      tap(conversations => {
        this.conversations = conversations.list.map(conversation => {
          return {...conversation, messages: [conversation.lastMessage]}
        })
        this.unreadMessageCountSubject.next(this.conversations.reduce((acc, conv) => acc + conv.unreadMessageCount, 0))
      })
    )
  }

  getConversation(conversationId: string): Observable<ConversationResponse> {
    return this.http.get<ConversationResponse>(`${environment.apiUrl}/conversations/${conversationId}`).pipe(
      tap(conversation => {
        const conv = this.conversations.find(conv => conv.id === conversationId)
        if (!conv) {
          return
        }
        conv.messages = conversation.messages
      })
    )
  }

  getConversationMessages(conversationId: string): Observable<ConversationMessagesResponse> {
    return this.http.get<ConversationMessagesResponse>(`${environment.apiUrl}/conversations/${conversationId}/messages`).pipe(
      tap(messages => {
        const conversation = this.conversations.find(conv => conv.id === conversationId)
        if (!conversation) {
          return
        }
        conversation.messages = messages.list
      })
    )
  }

  startConversation(userId: string, firstMessageContent: string): Observable<ConversationSummaryResponse> {
    const request: StartConversationRequest = {
      userId,
      firstMessageContent
    }
    return this.http.post<ConversationSummaryResponse>(`${environment.apiUrl}/conversations`, request).pipe(
      tap(conversation => {
        this.conversations.unshift({...conversation, messages: [conversation.lastMessage]})
        this.unreadMessageCountSubject.next(this.conversations.reduce((acc, conv) => acc + conv.unreadMessageCount, 0))
      })
    );
  }

  sendMessage(conversationId: string, content: string): Observable<MessageResponse> {
    const request: MessageRequest = {
      content
    }
    console.log('sending message', request)
    return this.http.post<MessageResponse>(`${environment.apiUrl}/conversations/${conversationId}/messages`, request)
  }

  updateConversationReadIndex(conversationId: string, readIndex: number): Observable<ConversationSummaryResponse> {
    return this.http.put<ConversationSummaryResponse>(`${environment.apiUrl}/conversations/${conversationId}`, {readIndex: readIndex}).pipe(
      tap(response => {
        const conversation = this.conversations.find(conv => conv.id === conversationId)
        if (!conversation) {
          return
        }
        conversation.unreadMessageCount = response.unreadMessageCount
        this.unreadMessageCountSubject.next(this.conversations.reduce((acc, conv) => acc + conv.unreadMessageCount, 0))
      })
    )
  }
}

export type Conversation = {
  id: string
  otherUser: UserBasicResponse
  lastMessage: MessageResponse
  unreadMessageCount: number
  messages: MessageResponse[]
}
